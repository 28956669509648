import { isSafeUrl, normalizeUrl } from '@atlaskit/linking-common/url';

// regex to check that the url entered is at least of this format "://example.com"
const REGEX = /:\/\/(.*\.)?.+\..+/;

export const normalizeAndValidateUrl = (url: string) => {
	const normalizedUrl = normalizeUrl(url.trim());

	// `isSafeUrl()` treats a string with just the protocol (e.g. "http://") as a valid url so we need additional check
	const isValid = !!normalizedUrl && isSafeUrl(normalizedUrl) && REGEX.test(normalizedUrl);

	return isValid ? normalizedUrl : null;
};
