import { useCallback } from 'react';
import type { JsonLd } from 'json-ld-types';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { extractProduct } from '../extractProduct';

type ExtractEmbedProduct = {
	embedUrl: string;
	data?: JsonLd.Data.BaseData | null;
};

export const extractEmbedProduct = ({
	createAnalyticsEvent,
	data,
	embedUrl,
}: ExtractEmbedProduct & {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}) => {
	try {
		return extractProduct(embedUrl, data || undefined);
	} catch (error) {
		createAnalyticsEvent?.({
			type: 'sendOperationalEvent',
			data: {
				action: 'failed',
				actionSubject: 'embedProductExtraction',
				source: 'smartLinkEmbedProductExtraction',
				attributes: {
					contentType: 'embed',
				},
			},
		}).fire();
		return null;
	}
};

export const useExtractEmbedProduct = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const _extractEmbedProduct = useCallback(
		({ data, embedUrl }: ExtractEmbedProduct): string | null => {
			return extractEmbedProduct({ createAnalyticsEvent, data, embedUrl });
		},
		[createAnalyticsEvent],
	);

	return { extractEmbedProduct: _extractEmbedProduct };
};
