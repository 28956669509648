import { useCallback } from 'react';
import type { JsonLd } from 'json-ld-types';

import type { CardContext } from '@atlaskit/link-provider';
import { useSmartLinkContext } from '@atlaskit/link-provider';
import { extractLink, extractTitle } from '@atlaskit/link-extractors';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { extractIconUrl } from '../extractIconUrl';
import { handleEmbedAuthentication } from '../handleEmbedAuthentication';

import { extractEmbedProduct } from './useExtractEmbedProduct';

type ExtractEmbedData = {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	data: JsonLd.Data.BaseData | undefined;
	embedUrl: string;
};

const extractEmbedData = ({ createAnalyticsEvent, data, embedUrl }: ExtractEmbedData) => {
	return {
		iconURL: data ? extractIconUrl(data) : undefined,
		product: extractEmbedProduct({
			createAnalyticsEvent,
			embedUrl,
			data,
		}),
		title: data ? extractTitle(data) : undefined,
		url: data ? extractLink(data) : undefined,
	};
};

type FetchEmbedData = {
	embedUrl: string;
	includeAuthHandling?: boolean;
};

type FetchEmbedDataResult = {
	data: JsonLd.Data.BaseData | undefined;
	meta: JsonLd.Meta.BaseMeta | undefined;
	parsedEmbedData: {
		iconURL: string | undefined;
		product: string | null;
		title: string | undefined;
		url: string | undefined;
	};
	error: Error | null;
};

type FetchDataFromORS = FetchEmbedData & {
	cardClient: CardContext['connections']['client'];
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

export const fetchDataFromORS = async ({
	cardClient,
	createAnalyticsEvent,
	embedUrl,
	includeAuthHandling,
}: FetchDataFromORS): Promise<FetchEmbedDataResult> => {
	try {
		const details = await cardClient.fetchData(embedUrl);
		let data = (details && details.data) as JsonLd.Data.BaseData | undefined;
		let meta = details && details.meta;

		if (includeAuthHandling) {
			const authURL = meta.auth?.[0]?.url;
			if (meta.access === 'unauthorized' && authURL) {
				try {
					await handleEmbedAuthentication({ authURL });
				} catch (error) {
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							action: 'failed',
							actionSubject: 'outboundAuthFlow',
							source: 'smartLinkEmbedAuthentication',
							attributes: {
								error: error.type || 'unknown',
								extensionKey: meta?.key ?? null,
								destinationObjectType: meta?.resourceType ?? null,
								contentType: 'embed',
							},
							nonPrivacySafeAttributes: {
								linkDomain: extractEmbedProduct({
									createAnalyticsEvent,
									data,
									embedUrl,
								}),
							},
						},
					}).fire();

					return {
						data,
						meta,
						parsedEmbedData: extractEmbedData({
							createAnalyticsEvent,
							data,
							embedUrl,
						}),
						error: null,
					};
				}

				// We have successfully authenticated with the URL, so lets refetch to get all the
				// proper data.
				const refetchedDetails = await cardClient.fetchData(embedUrl);
				meta = refetchedDetails && refetchedDetails.meta;

				if (refetchedDetails && refetchedDetails.data) {
					data = refetchedDetails.data as JsonLd.Data.BaseData;
				}
			}
		}

		return {
			data,
			meta,
			parsedEmbedData: extractEmbedData({
				createAnalyticsEvent,
				data,
				embedUrl,
			}),
			error: null,
		};
	} catch (error) {
		return {
			data: undefined,
			meta: undefined,
			parsedEmbedData: extractEmbedData({
				createAnalyticsEvent,
				data: undefined,
				embedUrl,
			}),
			error,
		};
	}
};

export const useFetchDataFromORS = () => {
	const { connections } = useSmartLinkContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetchEmbedData = useCallback(
		async ({ embedUrl, includeAuthHandling }: FetchEmbedData): Promise<FetchEmbedDataResult> => {
			return fetchDataFromORS({
				cardClient: connections.client,
				createAnalyticsEvent,
				embedUrl,
				includeAuthHandling,
			});
		},
		[connections.client, createAnalyticsEvent],
	);

	return {
		fetchEmbedData,
	};
};
