import type { JsonLd } from 'json-ld-types';

import { extractUrlFromIconJsonLd } from '@atlaskit/link-extractors';

export const isValidScheme = (url: string): boolean => {
	const httpPattern = /^https?:\/\/[^\s]*$/im;
	return httpPattern.test(url);
};

export const extractIconUrl = (data: JsonLd.Data.BaseData) => {
	/*
	 * data.generator.icon.url is where provider icon is and
	 * data.icon.url is where the link icon usually is. In most cases,
	 * they are the same thing but some providers have specific icons
	 * for specific links. For example, a Google Sheet link from
	 * google-object-provider would return Google Drive icon as a provider
	 * icon (data.generator.icon.url) but would return Google Sheets icon
	 * as link icon (data.icon.url).
	 */
	const generatorIconObj = (data.generator as JsonLd.Primitives.Object)?.icon;
	const iconObj = data.icon;
	const generatorIcon = (generatorIconObj && extractUrlFromIconJsonLd(generatorIconObj)) || '';
	const icon = (iconObj && extractUrlFromIconJsonLd(iconObj)) || '';

	// TEMP: calling isValidScheme here is a workaround to
	// ensure data URIs for favicons don't make it through
	// (BE fails as it expects a http(s) scheme). Long term,
	// we should support inlined data URI favicons.
	// https://hello.jira.atlassian.cloud/browse/CTIA-1918
	return (isValidScheme(icon) && icon) || (isValidScheme(generatorIcon) && generatorIcon) || '';
};
