import React from 'react';

import { token } from '@atlaskit/tokens';
import { Flex, xcss } from '@atlaskit/primitives';

const fullHeightContainer = xcss({
	height: 'calc(100vh - var(--bannerHeight, 0px) - var(--topNavigationHeight, 0px))',
});
const dotGridBackground = xcss({
	backgroundImage: `radial-gradient(circle at 2px 2px, ${token(
		'elevation.surface.overlay.hovered',
	)} 2px, transparent 0)`,
	backgroundSize: '20px 20px',
});

export const DotGridBackground = ({
	showDottedBackground,
	children,
}: {
	showDottedBackground: boolean;
	children: React.ReactNode;
}) => {
	return (
		<Flex
			direction="column"
			xcss={[fullHeightContainer, showDottedBackground && dotGridBackground]}
		>
			{children}
		</Flex>
	);
};
