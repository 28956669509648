import { auth } from '@atlaskit/outbound-auth-flow-client';

type FetchFromORSConfig = {
	authURL: string;
};

export const handleEmbedAuthentication = async ({ authURL }: FetchFromORSConfig) => {
	const POPUP_HEIGHT = 600;
	const POPUP_WIDTH = 720;
	const BROWSER_SIZE_THRESHOLD = 48;

	const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

	// Center the popup window
	const left = (vw - POPUP_WIDTH) / 2;
	const top = (vh - POPUP_HEIGHT) / 2;

	// Open a popup if there's enough space in the viewport
	// Otherwise, fall back to the default behavior (opening in a new tab)
	const windowFeatures =
		vw > POPUP_WIDTH + BROWSER_SIZE_THRESHOLD && vh > POPUP_HEIGHT + BROWSER_SIZE_THRESHOLD
			? `width=${POPUP_WIDTH},height=${POPUP_HEIGHT}},left=${left},top=${top}`
			: undefined;

	await auth(authURL, windowFeatures);
};
