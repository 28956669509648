import type { JsonLd } from 'json-ld-types';

import { extractProvider } from '@atlaskit/link-extractors';

// Gets a more specific product for the URL if we know that a provider can cover
// multiple products
const getSpecificProduct = (hostname: string, providerName?: string): string | undefined => {
	if (providerName === 'OneDrive' && hostname.includes('sharepoint')) {
		return 'SharePoint';
	} else {
		return providerName;
	}
};

export const extractProduct = (embedUrl: string, data?: JsonLd.Data.BaseData) => {
	const url = new URL(embedUrl);
	const urlHostname = url.hostname;
	// we want to remove the "www." at the beginning of the URL if it exists
	// so that the analytics are receiving domains in a consistent format
	const parsedHostname = urlHostname.replace(/^www\./, '');

	if (!data) {
		return parsedHostname;
	}

	const provider = extractProvider(data);
	const providerName = provider?.text;
	const embedProduct = getSpecificProduct(urlHostname, providerName);
	const providerIsHostname = embedProduct !== undefined && embedProduct.includes(parsedHostname);

	// ORS will sometimes return the urlHostname as the provider so use
	// the parsedHostname is there is no providerName or if the provider
	// contains the hostname
	if (!embedProduct || providerIsHostname) {
		return parsedHostname;
	}

	return embedProduct;
};
